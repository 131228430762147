"use client";
import styled from "styled-components";

const Container = styled.div`
  background: black;
  display: flex;
  height: calc(100vw / 3);
  width: 100%;

  ${props => props.theme.mQ.md} {
    height: calc(100vw / 6);
    height: ${props => (props.columntotal === 3 ? `calc(100vw / 6)` : `22.5vw`)};
  }

  a {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    opacity: 0.8;
    transition: opacity 250ms ease-in-out;
    width: 100%;

    &:hover {
      opacity: 1;
    }
  }

  img {
    opacity: 0;
    transform: scale(0.75);
    width: auto;
    height: auto;

    ${props => props.theme.mQ.sm} {
      transform: none;
    }

    ${props => props.theme.mQ.md} {
      transform: scale(0.75);
    }

    ${props => props.theme.mQ.lg} {
      transform: none;
    }

    ${props => props.theme.mQ.xl} {
      transform: scale(1.4);
    }
  }
`;

export default function FeaturedLogo({ children, columnTotal }) {
  return <Container columntotal={columnTotal}>{children}</Container>;
}
