export default function SaturdayLogo({ className, fill = "white", ref }) {
  return (
    <svg
      alt="Saturday logo"
      className={className}
      viewBox="0 0 562 108"
      fill="none"
      ref={ref}
      xmlns="http://www.w3.org/2000/svg">
      <path
        style={{ fill: fill }}
        d="M562,30.51l-19.11,0.54c-0.59-6.39-2.02-13.77-9.31-13.77c-5.8,0-7.55,4.95-7.55,9.58 c0,5.67,3.64,9.9,9.62,15.52l9.85,9.72c9.31,8.68,14.93,17.28,14.93,28.57c0,14.4-8.99,26.69-27.43,26.69 c-12.9,0-24.95-6.66-25.81-29.25l17.98-0.58c0.76,7.51,2.47,13.5,8.86,13.5c5.12,0,7.42-3.92,7.42-8.69 c0-5.53-5.66-10.75-10.61-15.39l-9.13-9c-9.98-9.58-15.65-18.4-15.65-30.01c0-15.3,10.43-27.27,27.7-27.27 C550.98,0.58,561.01,12.1,562,30.51z M409.86,88.43h19.56v17.28H369.8V88.43h19.47V2.2h20.59V88.43z M356.58,28.03v77.71h-20.59v-77 c0-6.84-3.91-10.3-9.26-10.3c-5.8,0-8.99,4.05-8.86,10.35v76.9h-19.6V28.62c0-18.14,12.9-28.04,29.72-28.04 C345.7,0.58,356.58,9.36,356.58,28.03z M277.54,105.71h-29.67c-17.44,0-28.01-9.58-28.01-30.6c0-14.99,4.77-23.4,12.05-27.32 L218.51,2.2h21.18l10.52,41.09h6.83V2.25h20.46v103.5L277.54,105.71z M249.57,58.5c-5.8,0-9.31,4.05-9.31,15.3 c0,9.67,3.06,14.89,9.44,14.89h7.37V58.54h-7.51V58.5z M201.56,105.71h-29.81c-15.83,0-28.28-8.1-28.28-24.97V28.49 c0-18,12.45-26.28,28.42-26.28h29.72v103.5H201.56z M173.59,19.75c-6.97,0-9.44,4.95-9.44,9.9v49.23c0,5.4,3.06,9.45,9.71,9.45h7.42 V19.8h-7.73L173.59,19.75z M42.22,43.02l21.58,62.69H42.04L30.62,66.92l-11.06,38.79H0l21.58-62.87V2.25h20.59v40.82L42.22,43.02z M113.52,2.25l-4,23.22H88.03L84.08,2.2H63.3l20.14,103.68h27.88L132.41,2.16h-18.88V2.25z M98.46,87.21l-7.64-45.22h15.87 L98.91,87.3h-0.45V87.21z M480.08,2.25l-4.05,23.22h-21.49L450.63,2.2h-20.73l20.14,103.68h27.88L498.88,2.16h-18.88L480.08,2.25z M464.98,87.25l-7.64-45.27h15.92l-7.82,45.27H464.98z"
      />
    </svg>
  );
}
