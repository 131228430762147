"use client";
import styled from "styled-components";

const Container = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.nearBlack};
  display: flex;
  height: calc(100vw / 3 * 2);
  justify-content: center;
  width: 100%;
  padding: 10vw 3.33vw;
  position: sticky;
  top: 0;
  text-align: center;

  ${props => props.theme.mQ.md} {
    height: 50vw;
    padding: 6.66vw;
  }

  a {
    &:active,
    &:hover,
    &:focus {
      color: white;
    }
  }

  h2 {
    font-family: var(--font-display), sans-serif;
  }
`;

export default function BigSquare({ className, children }) {
  return <Container className={className}>{children}</Container>;
}
