"use client";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  /* Fonts */
  @font-face {
    font-family: "Control";
    src: url("/fonts/ControlUpright-Regular-Web.woff") format("woff"),
      url("/fonts/ControlUpright-Regular-Web.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Control TNT";
    src: url("/fonts/ControlUpright-RegularTNT-Web.woff") format("woff"),
      url("/fonts/ControlUpright-RegularTNT-Web.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html,
  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: Control, sans-serif;
    max-width: 100vw;
    scroll-behavior: smooth;
  }

  html {
    opacity: 1 !important;
  }

  body {
    ${props => props.theme.mixins.fontSize.sm};
    color: white;
    background: black;
  }

  a {
    cursor: pointer;
    color: ${props => props.theme.colors.midGray};
    text-decoration: none;
  }

  h2 {
  font-family: 'Control TNT', sans-serif;
    ${props => props.theme.mixins.fontSize.lg};
    font-weight: 500;
  }

  // Animation styles
  .animation-riseAndFadeIn {
    opacity: 0;
  }
`;

export default function GlobalStyles() {
  return <GlobalStyle />;
}
