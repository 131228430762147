"use client";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useRef } from "react";
import gsap from "gsap";
import styled from "styled-components";

// Components
import BigSquare from "./BigSquare";
import SaturdayLogo from "./SaturdayLogo";
import FeaturedLogo from "./FeaturedLogo";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const BigSquareFinal = styled(BigSquare)`
  top: 100vw;
  height: 100vw;
  position: sticky;
  z-index: 1;

  ${props => props.theme.mQ.md} {
    border-left: 1px solid ${props => props.theme.colors.darkGray};
    height: 50vw;
    top: 0;
  }
`;

const Container = styled.main`
  display: flex;
  flex-direction: column;

  svg {
    width: 100%;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 2;

  ${props => props.theme.mQ.md} {
    width: 50%;
  }

  > div:not(:first-child) {
    border-top: 1px solid ${props => props.theme.colors.darkGray};
  }
`;

const ColumnInstaBirdies = styled(Column)`
  ${props => props.theme.mQ.md} {
    margin-bottom: 5vw;
  }
`;

const ColumnFinal = styled(Column)`
  height: 200vw;

  ${props => props.theme.mQ.md} {
    height: 100vw;
  }
`;

const ColumnSticky = styled(Column)`
  height: calc(100vw / 3 * 4);
  z-index: 1;
  margin-bottom: calc(-100vw / 3 * 2);

  ${props => props.theme.mQ.md} {
    height: 100vw;
    margin-bottom: -100vw;
  }
`;

const ColumnStickyTwoLogos = styled(ColumnSticky)`
  height: calc(100vw / 3 * 4);
  z-index: 2;
  margin-bottom: calc(-100vw / 3 * 2);

  ${props => props.theme.mQ.md} {
    height: 95vw;
    margin-bottom: -100vw;
  }
`;

const SaturdayLogoColumn = styled(ColumnStickyTwoLogos)``;

const SaturdayLogoWrapper = styled.div`
  width: 82%;
`;

const ColumnThreeLogos = styled(Column)`
  width: 100%;
`;

const ColumnThreeLogosInner = styled(Column)`
  margin-bottom: -100vw;
  width: 100%;
`;

const Row = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${props => props.theme.mQ.md} {
    align-items: stretch;
    flex-direction: row;
  }

  &:first-child ${Column} {
    background: none;

    > div {
      ${props => props.theme.mQ.md} {
        border-right: 1px solid ${props => props.theme.colors.darkGray};
      }
    }
  }

  // bump the big square in the second row up on larger screens
  &:nth-child(even) {
    > div:nth-child(2) {
      ${props => props.theme.mQ.md} {
        margin-top: -5vw;
        background-color: ${props => props.theme.colors.nearBlack};
      }
    }

    ${Column}:first-child {
      ${props => props.theme.mQ.max.md} {
        order: 2;
      }
    }
  }
`;

export default function HomeBody() {
  const containerRef = useRef();
  const logoTopRef = useRef();

  useGSAP(() => {
    const windowWidth = window.innerWidth;
    const oneColView = windowWidth < 900;

    // Rise and fades
    ScrollTrigger.batch(".animation-riseAndFadeIn", {
      onEnter: el => {
        gsap.fromTo(
          el,
          {
            opacity: 0,
            yPercent: 20,
          },
          {
            stagger: 0.125,
            duration: 3,
            yPercent: 0,
            ease: "power3.out",
            opacity: 1,
          }
        );
      },
      once: true,
      start: "center 90%",
    });

    // Top logo animation
    gsap.fromTo(
      logoTopRef.current,
      {
        scale: 1,
      },
      {
        scale: 0.8,
        autoAlpha: 0,
        ease: "none",
        scrollTrigger: {
          trigger: logoTopRef.current,
          start: "top 15%",
          scrub: true,
          markers: false,
        },
      }
    );

    // First statement animation
    gsap.fromTo(
      ".animation-statement_1",
      {
        scale: 0.75,
        autoAlpha: 0.05,
      },
      {
        scale: 1,
        autoAlpha: 1,
        ease: "none",
        scrollTrigger: {
          // start: "center 80%",
          end: oneColView ? "center 125%" : "center 100%",
          scrub: true,
          markers: false,
        },
      }
    );

    // Second statement animation
    gsap.fromTo(
      ".animation-statement_2",
      {
        scale: 0.75,
        autoAlpha: 0.05,
      },
      {
        scale: 1,
        autoAlpha: 1,
        ease: "none",
        scrollTrigger: {
          start: oneColView ? "center 100%" : "center 90%",
          end: "center 50%",
          scrub: true,
          markers: false,
        },
      }
    );

    return () => {
      gsap.killTweensOf("*");
    };
  }, [containerRef, logoTopRef]);

  return (
    <Container ref={containerRef}>
      <Row>
        <SaturdayLogoColumn>
          <BigSquare>
            <SaturdayLogoWrapper ref={logoTopRef}>
              <SaturdayLogo className="animation-riseAndFadeIn" />
            </SaturdayLogoWrapper>
          </BigSquare>
        </SaturdayLogoColumn>
        <ColumnInstaBirdies>
          <FeaturedLogo columnTotal={2}>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <img
                className="animation-riseAndFadeIn featuredLogo"
                alt="Instagram logo"
                src="/img/logo-instagram.svg"
              />
            </a>
          </FeaturedLogo>
          <FeaturedLogo columnTotal={2}>
            <a href="https://birdies.com" target="_blank" rel="noopener noreferrer">
              <img
                className="animation-riseAndFadeIn featuredLogo"
                alt="Birdies logo"
                src="/img/logo-birdies.svg"
              />
            </a>
          </FeaturedLogo>
        </ColumnInstaBirdies>
      </Row>
      <Row>
        <Column>
          <FeaturedLogo columnTotal={2}>
            <a href="https://gopuff.com" target="_blank" rel="noopener noreferrer">
              <img
                className="animation-riseAndFadeIn featuredLogo"
                alt="gopuff logo"
                src="/img/logo-gopuff.svg"
              />
            </a>
          </FeaturedLogo>
          <FeaturedLogo columnTotal={2}>
            <a href="https://unsplash.com" target="_blank" rel="noopener noreferrer">
              <img
                className="animation-riseAndFadeIn featuredLogo"
                alt="unsplash logo"
                src="/img/logo-unsplash.svg"
              />
            </a>
          </FeaturedLogo>
        </Column>
        <ColumnStickyTwoLogos>
          <BigSquare>
            <h2 className="animation-statement_1">
              We design enduring, authentic identities that clarify and inspire.
            </h2>
          </BigSquare>
        </ColumnStickyTwoLogos>
      </Row>
      <Row>
        <ColumnSticky>
          <BigSquare>
            <h2 className="animation-statement_2">
              Devoted to the value of design, we&nbsp;operate as a cohesive team,
            </h2>
          </BigSquare>
        </ColumnSticky>
        <ColumnFinal>
          <ColumnThreeLogos>
            <ColumnThreeLogosInner>
              <FeaturedLogo columnTotal={3}>
                <a href="https://affirm.com" target="_blank" rel="noopener noreferrer">
                  <img
                    className="animation-riseAndFadeIn featuredLogo"
                    alt="affirm logo"
                    src="/img/logo-affirm.svg"
                  />
                </a>
              </FeaturedLogo>
              <FeaturedLogo columnTotal={3}>
                <a href="https://www.meta.com/quest/" target="_blank" rel="noopener noreferrer">
                  <img
                    className="animation-riseAndFadeIn featuredLogo"
                    alt="oculus logo"
                    src="/img/logo-oculus.svg"
                  />
                </a>
              </FeaturedLogo>
              <FeaturedLogo columnTotal={3}>
                <a href="https://silk.com" target="_blank" rel="noopener noreferrer">
                  <img
                    className="animation-riseAndFadeIn featuredLogo"
                    alt="silk logo"
                    src="/img/logo-silk.svg"
                  />
                </a>
              </FeaturedLogo>
            </ColumnThreeLogosInner>
          </ColumnThreeLogos>
          <BigSquareFinal>
            <h2>transforming brands to thrive today and honor a timeless vision.</h2>
          </BigSquareFinal>
        </ColumnFinal>
      </Row>
    </Container>
  );
}
