const breakpoints = {
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1920,
};

const colors = {
  nearBlack: "#161216",
  darkGray: "#1e1a1e",
  midGray: "#808080",
  offWhite: "#FBF9F9",
};

const grid = {
  gutter: {
    initial: 16,
    md: 20,
  },
  marginLR: {
    initial: 16,
    md: 24,
  },
};

const header = {
  height: 55,
};

const mQ = {
  sm: `@media only screen and (min-width: ${breakpoints.sm}px)`,
  md: `@media only screen and (min-width: ${breakpoints.md}px)`,
  lg: `@media only screen and (min-width: ${breakpoints.lg}px)`,
  xl: `@media only screen and (min-width: ${breakpoints.xl}px)`,
  max: {
    sm: `@media only screen and (max-width: ${breakpoints.sm - 1}px)`,
    md: `@media only screen and (max-width: ${breakpoints.md - 1}px)`,
    lg: `@media only screen and (max-width: ${breakpoints.lg - 1}px)`,
    xl: `@media only screen and (max-width: ${breakpoints.xl - 1}px)`,
  },
};

const transitions = {
  highlight: "250ms ease-in-out",
  siteLoad: "1.67s ease-in-out",
};

const type = {
  letterSpacing: {
    sm: 0,
    md: 0,
    lg: "-0.02em",
  },
  lineHeight: {
    sm: 1.5,
    md: 1.5,
    lg: 1.4,
  },
  size: {
    sm: {
      initial: "14px",
      md: "16px",
      xl: "19px",
    },
    md: {
      initial: "16px",
      md: "19px",
      xl: "24px",
    },
    lg: {
      initial: "5.5vw",
      md: "2.2222vw",
    },
  },
};

const mixins = {
  fontSize: {
    sm: `
      font-size: ${type.size.sm.initial};
      letter-spacing: ${type.letterSpacing.sm};
      line-height: ${type.lineHeight.sm};

      ${mQ.md} {
        font-size: ${type.size.sm.md};
      }

      ${mQ.xl} {
        font-size: ${type.size.sm.xl};
      }
    `,
    md: `
      font-size: ${type.size.md};
      letter-spacing: ${type.letterSpacing.md};
      line-height: ${type.lineHeight.md};

      ${mQ.md} {
        font-size: ${type.size.md.md};
      }

      ${mQ.xl} {
        font-size: ${type.size.md.xl};
      }
    `,
    lg: `
    font-size: ${type.size.lg.initial};
    font-weight: 400;
    letter-spacing: ${type.letterSpacing.lg};
    line-height: ${type.lineHeight.lg};

      ${mQ.md} {
        font-size: ${type.size.lg.md};
      }
    `,
  },
  marginsLR: `
    padding-left: ${grid.marginLR.initial}px;
    padding-right: ${grid.marginLR.initial}px;

    @media only screen and (min-width: ${breakpoints.md}px) {
      padding-left: ${grid.marginLR.md}px;
      padding-right: ${grid.marginLR.md}px;
    }
  `,
};

const theme = {
  breakpoints,
  colors,
  header,
  mQ,
  mixins,
  type,
  transitions,
};

export default theme;
