"use client";
import { useGSAP } from "@gsap/react";
import { useRef } from "react";
import gsap from "gsap";
import styled from "styled-components";
import SaturdayLogo from "./SaturdayLogo";

const Container = styled.footer`
  align-items: center;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 2.5vw;
  height: 67vw;
  justify-content: space-between;
  padding: 5vw 7.5vw;
  position: relative;
  text-align: center;
  width: 100%;

  ${props => props.theme.mQ.sm} {
    height: 50vw;
  }

  ${props => props.theme.mQ.md} {
    padding: 3.75vw 7.5vw;
    height: 40vw;
  }

  img {
    width: 107%;
  }

  p {
    color: black;
    line-height: 1.75;
  }
`;

const Copy = styled.div`
  @media (min-width: 1000px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 1em;
  }
`;

const InfoLink = styled.a`
  position: absolute;
`;

const thisYear = new Date().getFullYear();

export default function Footer() {
  const containerRef = useRef();

  useGSAP(() => {
    gsap.utils.toArray(".animation-riseAndFadeIn_footer").forEach(el => {
      let duration = 3;
      let yPercentage = 20;
      if (el.classList.contains("animation-riseAndFadeIn_footer_logo")) {
        duration = 2.125;
        yPercentage = 10;
      }
      gsap.fromTo(
        el,
        {
          yPercent: yPercentage,
          opacity: 0,
        },
        {
          duration: duration,
          ease: "power3.out",
          opacity: 1,
          yPercent: 0,
          scrollTrigger: {
            start: "center bottom",
            trigger: el,
            toggleActions: "play none none reset",
          },
        }
      );
    });
  }, [containerRef]);

  return (
    <Container ref={containerRef}>
      <Copy className="animation-riseAndFadeIn_footer">
        <p>
          <a href="mailto:info@saturdaydesign.com">info@saturdaydesign.com</a>&ensp;
        </p>
        <p>107 Roebling&emsp;Brooklyn, New York</p>
        <p>&copy; {thisYear} Saturday, Inc. All rights reserved.</p>
      </Copy>
      <SaturdayLogo
        fill="black"
        className="animation-riseAndFadeIn_footer animation-riseAndFadeIn_footer_logo"
      />
      <InfoLink id="info" />
    </Container>
  );
}
