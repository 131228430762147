import { ThemeProvider } from "styled-components";

import theme from "./components/theme";
import GlobalStyles from "./components/GlobalStyles";
import Footer from "./components/Footer";
import HomeBody from "./components/HomeBody";
import Nav from "./components/Nav";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Nav />
      <HomeBody />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
