"use client";
import styled from "styled-components";

const Container = styled.header`
  ${props => props.theme.mixins.fontSize.m};
  ${props => props.theme.mixins.marginsLR};
  align-items: center;
  display: flex;
  height: ${props => props.theme.header.height}px;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
`;

export default function Nav() {
  return (
    <Container>
      <a href="#info">Info</a>
    </Container>
  );
}
